<template>
  <div>
    <v-md-preview :text="markdownContent"></v-md-preview>
  </div>
</template>

<script>
import markdownList from "@mock/markdownList.js";
import axios from "axios";
export default {
  name: "detailPage",
  components: {},
  watch: {
    "$route.query.fileName": {
      immediate: true,
      handler() {
        this.init();
      }
    }
  },
  data() {
    return {
      markdownContent: ``,
      markdownList
    };
  },
  methods: {
    init() {
      const urlParams = this.$client.getUrlParams();
      const { fileName } = urlParams;
      const path =
        this.markdownList[fileName] && this.markdownList[fileName].path;
      axios
        .get(path)
        .then(res => {
          this.markdownContent = res.data;
        })
        .catch(error => {
          console.error(`读取文件失败:`, error);
        });
    }
  },
};
</script>