<template>
  <div>
    <el-row>
      <el-col :span="8" :offset="1">
        <el-button @click="getDirectory">打开目录</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8" :offset="1">
        <el-tree :data="fileList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-col>
      <el-col :span="12" :offset="2">
        <div>{{ fileContent }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "codePage",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name"
      },
      fileList: [],
      fileContent: ""
    };
  },
  methods: {
    /**
     * 获取目录
     */
    async getDirectory() {
      const handle = await window.showDirectoryPicker();
      await processHandle(handle);
      this.fileList = [handle];

      async function processHandle(handle) {
        if (handle.kind === "file") {
          return;
        }
        handle.children = [];
        const iter = await handle.entries();
        for await (const entry of iter) {
          const subHandle = entry[1];
          handle.children.push(subHandle);
          await processHandle(subHandle);
        }
      }
    },
    /**
     * 选择文件
     */
    async handleNodeClick(data) {
      if (data.kind === "directory") {
        return;
      }
      const file = await data.getFile();
      const reader = new FileReader();
      reader.onload = e => {
        this.fileContent = e.target.result;
        console.log(this.fileContent);
      };
      reader.readAsText(file);
    }
  }
};
</script>