<template>
  <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <el-row>
        <el-col
          :xs="{span: 22, offset: 1}"
          :sm="{span: 22, offset: 1}"
          :md="{span: 22, offset: 1}"
          :lg="{span: 22, offset: 1}"
          :xl="{span: 22, offset: 1}"
        >
          <div class="content-box">
            <div class="content-title">由浅入深的编程学习笔记</div>
            <p>本站记录的是前、后端编程及数据库开发技术的学习历程。</p>
            <p>每一段代码、每一个项目、每一个困难，都是编程生涯中成长的足迹。</p>
            <p>相信学习编程是一件既开心又有成就感的事情。</p>
          </div>
        </el-col>
      </el-row>
      <el-row class="grid-box">
        <el-col :span="24">
          <div class="grid-list">
            <div
              v-for="(item, index) in homeMenuList"
              :key="index"
              class="cursor grid-list-item"
              :class="gridIndex === index ? 'grid-list-item-active' : ''"
              @click="clickHandler(item, index)"
            >{{ item.title }}</div>
          </div>
          <el-row class="grid-box-describe">
            <el-col :span="20" :offset="2">
              <div class="describe-content">{{ describe }}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import homeMenuList from "@mock/homeMenuList.js";
export default {
  name: "homePage",
  data() {
    return {
      describe: `计算机编程语言用于实现人与机器之间的交流和互动，按不同标准有多种不同分类，本站主要关注的是前端编程语言、后端编程语言和数据库编程语言，并对围绕此三类语言扩展的第三方框架进行深入研究。`,
      gridIndex: -1,
      homeMenuList,
    };
  },
  methods: {
    clickHandler(item, index) {
      this.describe = item.describe;
      this.gridIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
.content-box {
  transition: width 0.3s; /* 平滑过渡效果 */
  margin-bottom: 18px;
  .content-title {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    margin: 20px 0 12px;
  }
  p {
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    margin-bottom: 8px;
  }
}

.grid-box {
  background: #eeeeee;
  .grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 24px 0 24px;
    .grid-list-item {
      text-align: center;
      color: #000000;
      margin-bottom: 12px;
    }
    .grid-list-item-active {
      color: #d88400;
    }
  }
  .grid-box-describe {
    padding-bottom: 50px;
    .describe-content {
      font-size: 12px;
      line-height: 22px;
      padding: 5px 20px;
      border-right: 4px #ebe8cf solid;
      border-left: 4px #ebe8cf solid;
      border-radius: 20px;
      margin: auto;
    }
  }
}

@media (min-width: 320px) {
  /* 移动设备 */
  .content-box {
    .content-title {
      font-size: 18px;
      text-align: center;
      line-height: 22px;
      margin: 20px 0 12px;
    }
    p {
      font-size: 14px;
      text-align: center;
      line-height: 18px;
    }
  }
  .grid-box {
    background: #eeeeee;
    .grid-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 24px 0 24px;
    }
  }
  .grid-box-describe {
    .describe-content {
      max-width: 800px;
      margin: auto;
      font-size: 12px;
      line-height: 18px;
      padding: 5px 20px;
    }
  }
}

@media (min-width: 768px) {
  .content-box {
    /* 桌面电脑 */
    .content-title {
      font-size: 32px;
      text-align: center;
      line-height: 48px;
      margin: 50px 0 30px;
    }
    p {
      font-size: 24px;
      text-align: center;
      line-height: 48px;
    }
  }
  .grid-box {
    background: #eeeeee;
    .grid-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      max-width: 800px;
      padding: 24px 0 24px;
      margin: auto;
    }
    .grid-box-describe {
      .describe-content {
        max-width: 800px;
        margin: auto;
        font-size: 16px;
        line-height: 32px;
        padding: 5px 20px;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}
</style>
