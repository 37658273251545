<template>
  <el-row class="main-box-foot">
    <el-col
      :xs="24"
      :sm="24"
      :md="{span: 8, offset: 2}"
      :lg="{span: 8, offset: 2}"
      :xl="{span: 8, offset: 2}"
    >
      <div class="main-box-foot-item">
        <span style="font-family:arial;">Copyright &copy;2024 ghostangel909.cn. All rights reserved.</span>
      </div>
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="{span: 6, offset: 0}"
      :lg="{span: 6, offset: 0}"
      :xl="{span: 6, offset: 0}"
    >
      <div class="main-box-foot-item">
        <img src="@assets/image/beian_icon.png" />
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44010502003160"
          rel="noreferrer"
          target="_blank"
        >粤公网安备44010502003160号</a>
      </div>
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="{span: 4, offset: 0}"
      :lg="{span: 4, offset: 0}"
      :xl="{span: 4, offset: 0}"
    >
      <div class="main-box-foot-item">
        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2024238515号-2</a>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
    name: 'navFoot',
}
</script>

<style lang="less" scoped>
.main-box-foot {
  text-align: center;
  .main-box-foot-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
    img {
      width: 16px;
    }
    a {
      color: #0085f2;
    }
  }
}
</style>