<template>
  <el-submenu v-if="item.children" :index="item.menuId">
    <template slot="title">
      <i v-if="item.navLevel === 1" class="el-icon-menu"></i>
      <span>{{ item.menuName }}</span>
    </template>
    <recursive-menu v-for="child in item.children" :key="child.menuId" :item="child" />
  </el-submenu>
  <el-menu-item v-else :index="item.menuId">
    <i v-if="item.navLevel === 1" class="el-icon-menu"></i>
    <span>{{ item.menuName }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: "recursiveMenu",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>