// 配置路由相关信息
import VueRouter from 'vue-router';
import Vue from 'vue';
import routesData from './routes.js';

// 安装插件
Vue.use(VueRouter);


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法，捕获跳转相同路由的报错
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

// 创建路由对象
const routes = routesData.filter(item => item.path);

// 配置路由与组件间的映射关系
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// 路由守卫
router.beforeEach((to, from, next) => {
    // 从 to.meta 中获取新页面的标题
    const newTitle = to.meta.title;
    if (newTitle) {
        // 使用 document.title 设置页面标题
        document.title = newTitle;
    }
    next();
});

// 导出实例
export default router;