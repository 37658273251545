export default {
    README: {
        title: '阅读',
        path: '/docs/README.md'
    },
    v_md_editor: {
        title: 'Vue2开发markdown页面',
        path: '/docs/v_md_editor.md'
    },
    vue_auto_router: {
        title: 'Vue自动生成路由',
        path: '/docs/vue_auto_router.md'
    },
    showDirectoryPicker: {
        title: 'Vue实现代码编写',
        path: '/docs/showDirectoryPicker.md'
    }
}