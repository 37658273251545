<template>
  <div id="app">
    <el-container style="height: 100vh; flex-direction: column">
      <h5-nav-menu class="hidden-lg-and-up"></h5-nav-menu>
      <div class="main-box-header hidden-md-and-down">
        <nav-logo></nav-logo>
        <web-nav-menu></web-nav-menu>
      </div>
      <router-view style="flex: 1"></router-view>
      <nav-foot></nav-foot>
    </el-container>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import webNavMenu from "@components/webNavMenu";
import h5NavMenu from "@components/h5NavMenu";
import navLogo from "@components/navLogo";
import navFoot from "@components/navFoot";
export default {
  name: "App",
  components: {
    webNavMenu,
    h5NavMenu,
    navLogo,
    navFoot
  }
};
</script>

<style>
.main-box-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>