<template>
  <div>
    <v-list-group v-if="item.children" no-action :sub-group="item.navLevel === 2" :value="false">
      <template v-slot:activator>
        <v-list-item-title>{{ item.menuName }}</v-list-item-title>
      </template>
      <recursive-menu
        v-for="(val, key) in item.children"
        :key="key"
        :item="val"
        @click="clickHandler"
      ></recursive-menu>
    </v-list-group>
    <v-list-item v-else @click="clickHandler(item)">
      <v-list-item-title
        :style="{textIndent: item.navLevel > 1 ? `${(item.navLevel - 1) * 2}em` : '0'}"
      >{{ item.menuName }}</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "recursiveMenu",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    clickHandler(item) {
      this.$emit("click", item);
    }
  }
};
</script>