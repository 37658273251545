import router from '@/router';
import { Message } from 'element-ui';

/**
 * listToTree
 * @param {*} list 
 * @returns tree
 */
function listToTree(list) {
    const map = new Map(list.map(item => [item.menuId, { ...item }]));
    const tree = [];
    for (const item of list) {
        if (item.parentId) {
            const parent = map.get(item.parentId);
            if (parent) {
                // 确保父级有 children 属性
                if (!parent.children) {
                    parent.children = [];
                }
                parent.children.push(map.get(item.menuId));
            }
        } else {
            tree.push(map.get(item.menuId));
        }
    }
    // 移除没有子菜单的项的 children 属性
    function removeEmptyChildren(node) {
        if (node.children && node.children.length === 0) {
            delete node.children;
        } else if (node.children) {
            node.children.forEach(removeEmptyChildren);
        }
    }
    tree.forEach(removeEmptyChildren);
    return tree;
}
/**
 * 弹框提示
 * @param {*} type 
 * @param {*} message 
 */
function alert(type = 'info', message) {
    Message({ type, message });
}

const client = {
    listToTree,
    alert,
    /**
     * 跳转页面
     * @param {*} path 
     * @param {*} query 
     * @param {*} params 
     */
    pushWindow(path, query = {}, params = {}) {
        router.push({ path, query, params }).catch(error => {
            console.error('路由跳转失败:', error);
        });
    },
    /**
     * 获取查询参数
     * @returns {}
     */
    getUrlParams() {
        return router.currentRoute.query;
    },
    /**
     * 获取路由参数
     * @returns {}
     */
    getParam() {
        return router.currentRoute.params;
    },
    /**
     * 点击导航栏
     * @param {*} item 
     */
    jumpNavFun(item) {
        switch (item.linkType) {
            case "INTERNAL_LINK":
                // 内部链接
                if (item.url === "/detail" && item.fileName) {
                    const params = {
                        fileName: item.fileName
                    };
                    client.pushWindow(item.url, params);
                } else {
                    client.pushWindow(item.url);
                }
                break;
            case "EXTERNAL_LINK":
                // 外部链接
                location.href = item.url;
                break;
            case "NOt_JUMP":
                // 不跳转
                client.alert('warning', "敬请期待哦！");
                break;
            default:
                client.alert('warning', "敬请期待！");
                break;
        }
    }
}
export default client;