// 使用 require.context 自动导入所有 Vue 组件
const modules = require.context('@/modules', true, /\.vue$/i);

// 创建一个路由配置数组
const routes = modules.keys().map(key => {
    // 获取组件名称
    const componentName = key.replace(/\.vue$/, '').split('/')[1];
    // 导入组件配置
    const pageConfig = require(`@/modules${key.replace('.', '').replace('index.vue', 'main.js')}`);
    
    return {
        path: `/${componentName}`,
        name: componentName,
        component: modules(key).default,
        meta: {
            title: pageConfig?.default?.title || ''
        }
    };
});

// 添加重定向规则到路由配置数组
routes.unshift({
    path: '/',
    redirect: '/home'
});

// 通配符路由，匹配所有未定义的路径
routes.push({
    path: '*',
    redirect: '/notFound'
})

export default routes;