<template>
  <div class="header-title">
    <img @click="clickHandler" src="@assets/image/COTC_logo.png" alt />
    <span>编程学习笔记</span>
  </div>
</template>

<script>
export default {
  name: "navLogo",
  methods: {
    clickHandler() {
      this.$client.pushWindow("/");
    }
  }
};
</script>

<style lang="less" scoped>
.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 50px;
  img {
    height: 50px;
    margin-right: 12px;
    cursor: pointer;
  }
}
</style>