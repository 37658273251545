/*
 * @Description: 入口文件
 * @Author: WenwuLi
 * @Date: 2022-12-04 22:41:11
 * @LastEditors: WenwuLi
 * @LastEditTime: 2023-01-07 22:23:14
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

// 重置标签样式
import './assets/css/reset.css';
import './assets/css/global.css';

// 引入elementUI框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入vuetify框架
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // 确保您正在使用 css
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
Vue.use(Vuetify);

// 引入v-md-editor
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VMdPreview);

// 公共方法
import client from './common/js/client'
if (client) {
  Vue.prototype.$client = client;
}

Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // 使用 Material Design Icons
    },
  }),
  render: h => h(App),
  router,
  store,
}).$mount('#app')
