import markdownList from "./markdownList";
const navMenuList = [
    {
        menuId: "001",
        menuName: "首页",
        navLevel: 1,
        linkType: 'INTERNAL_LINK',
        url: '/',
    },
    {
        menuId: "002",
        menuName: "前端技能",
        navLevel: 1,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "003",
        menuName: "文档",
        navLevel: 1,
        linkType: 'INTERNAL_LINK',
        url: '/detail',
        fileName: 'README',
    },
    {
        menuId: "004",
        menuName: "代码编辑器",
        navLevel: 1,
        linkType: 'INTERNAL_LINK',
        url: '/codePage',
    },
    {
        menuId: "005",
        menuName: "网络与服务",
        navLevel: 1,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "006",
        menuName: "数据库",
        navLevel: 1,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "007",
        menuName: "其他网站",
        navLevel: 1,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "002001",
        menuName: "Vue",
        parentId: "002",
        navLevel: 2,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "002002",
        menuName: "React",
        parentId: "002",
        navLevel: 2,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "002003",
        menuName: "微信小程序",
        parentId: "002",
        navLevel: 2,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "002004",
        menuName: "第三方框架或者库",
        parentId: "002",
        navLevel: 2,
        linkType: 'NOt_JUMP',
    },
    {
        menuId: "002004001",
        parentId: "002004",
        menuName: markdownList["v_md_editor"].title,
        navLevel: 3,
        linkType: 'INTERNAL_LINK',
        url: '/detail',
        fileName: 'v_md_editor',
    },
    {
        menuId: "002004002",
        parentId: "002004",
        menuName: markdownList["vue_auto_router"].title,
        navLevel: 3,
        linkType: 'INTERNAL_LINK',
        url: '/detail',
        fileName: 'vue_auto_router',
    },
    {
        menuId: "002004003",
        parentId: "002004",
        menuName: markdownList["showDirectoryPicker"].title,
        navLevel: 3,
        linkType: 'INTERNAL_LINK',
        url: '/detail',
        fileName: 'showDirectoryPicker',
    },
    {
        menuId: "007001",
        menuName: "盛世官网",
        parentId: "007",
        navLevel: 2,
        linkType: 'EXTERNAL_LINK',
        url: 'https://ghostangel909.cn/shengshi-web/'
    }
];
export default navMenuList;